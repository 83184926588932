<template>
  <div>
    <div class="options-holder">
      <div class="options-container">
        <div class="container">
          <div class="top-text">
            <h3>Select user type</h3>
            <p>How would you like to join Ovadraft?</p>
          </div>
          <div
            class="row mobile-card"
            style="flex-wrap: nowrap; overflow-x: scroll; justify-content: center"
          >
            <div class="col-md-4 col-sm-6">
              <div
                @click="userType = 'user'"
                class="top-card first-card"
                :class="[userType === 'user' ? 'activeCard' : '']"
              >
                <!-- <h6>Safe Balance</h6> -->
                <img
                  v-if="userType === 'user'"
                  class="customer-icon"
                  src="../../assets/icons/users.svg"
                  alt="customer"
                />
                <img
                  v-else
                  class="customer-icon"
                  src="../../assets/icons/users-gray.svg"
                  alt="customer"
                />
                <h5>Customer</h5>
              </div>
            </div>
            <div class="col-md-4 col-sm-4">
              <div
                @click="userType = 'agent'"
                class="top-card first-card"
                :class="[userType === 'agent' ? 'activeCard' : '']"
              >
                <img
                  v-if="userType === 'agent'"
                  src="../../assets/icons/headset-white.svg"
                  alt="agent"
                />
                <img v-else src="../../assets/icons/headset.svg" alt="agent" />
                <h5>
                  Agent
                </h5>
              </div>
            </div>
          </div>
          <div class="terms-conditions" v-if="userType != ''">
            <h4 class="tc">
              TERMS AND CONDITION
              <span v-if="userType === 'agent'">(AGENT)</span>
              <span v-if="userType === 'user'">(CUSTOMER)</span>
            </h4>
            <div class="terms-body" v-if="userType === 'user'">
              <h5>Lorem Ipsum</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur. Amet at nunc egestas
                enim proin tincidunt purus nisl suspendisse. Consectetur enim
                aliquet semper gravida sed vestibulum adipiscing. Sed urna hac
                lacinia orci vitae magna etiam nulla tempus. Orci tellus dapibus
                dolor magna fringilla hac. Morbi egestas faucibus dignissim
                metus sed purus ut. Mattis sem felis velit sed dictum amet
                nulla. Donec quis auctor nisi arcu nunc porttitor purus
                accumsan. Faucibus porttitor leo felis ac malesuada mattis. Leo
                nam id mi metus sapien aliquet. Quam purus urna ac senectus
                vitae. Odio quam arcu ipsum lectus. Sit mi elit blandit molestie
                orci natoque arcu. Congue molestie elit vitae nunc rhoncus
                lectus vulputate laoreet. In sit justo est in varius rhoncus et
                cursus. Vel tortor vitae vitae tristique tristique nec gravida
                sed sit. Erat et lectus blandit eget senectus sit in luctus. Hac
                amet ultrices id proin maecenas rhoncus. Quis semper nascetur
                massa ut ac. Malesuada tincidunt adipiscing nunc libero
                vulputate magna sed convallis. Tempor ut et nisi commodo nisl
                posuere vel. Ut eu commodo nisi imperdiet dictum sit in eleifend
                pellentesque. Massa fermentum dapibus leo purus mauris metus
                vitae. Id justo eu purus id at tortor orci et. Viverra eu
                blandit porta a vitae purus maecenas. Ut elementum sagittis
                pharetra adipiscing. Egestas erat viverra porttitor et. Id est
                tellus tortor molestie. Vehicula cursus egestas tortor pulvinar
                ac. Vulputate eget sed lacus aliquam consectetur viverra. Sed
                nisl quis viverra libero ac adipiscing rhoncus.
              </p>
            </div>
            <div class="terms-body" v-if="userType === 'agent'">
              <h5>Lorem Ipsum</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur. Amet at nunc egestas
                enim proin tincidunt purus nisl suspendisse. Consectetur enim
                aliquet semper gravida sed vestibulum adipiscing. Sed urna hac
                lacinia orci vitae magna etiam nulla tempus. Orci tellus dapibus
                dolor magna fringilla hac. Morbi egestas faucibus dignissim
                metus sed purus ut. Mattis sem felis velit sed dictum amet
                nulla. Donec quis auctor nisi arcu nunc porttitor purus
                accumsan. Faucibus porttitor leo felis ac malesuada mattis. Leo
                nam id mi metus sapien aliquet. Quam purus urna ac senectus
                vitae. Odio quam arcu ipsum lectus. Sit mi elit blandit molestie
                orci natoque arcu. Congue molestie elit vitae nunc rhoncus
                lectus vulputate laoreet. In sit justo est in varius rhoncus et
                cursus. Vel tortor vitae vitae tristique tristique nec gravida
                sed sit. Erat et lectus blandit eget senectus sit in luctus. Hac
                amet ultrices id proin maecenas rhoncus. Quis semper nascetur
                massa ut ac. Malesuada tincidunt adipiscing nunc libero
                vulputate magna sed convallis. Tempor ut et nisi commodo nisl
                posuere vel. Ut eu commodo nisi imperdiet dictum sit in eleifend
                pellentesque. Massa fermentum dapibus leo purus mauris metus
                vitae. Id justo eu purus id at tortor orci et. Viverra eu
                blandit porta a vitae purus maecenas. Ut elementum sagittis
                pharetra adipiscing. Egestas erat viverra porttitor et. Id est
                tellus tortor molestie. Vehicula cursus egestas tortor pulvinar
                ac. Vulputate eget sed lacus aliquam consectetur viverra. Sed
                nisl quis viverra libero ac adipiscing rhoncus.
              </p>
            </div>
            <div class="form-check form_check" v-if="userType != ''">
              <input
                class="form-check-input "
                type="checkbox"
                value=""
                id="flexCheckChecked"
                v-model="conditionterms"
              />
              <label class="form-check-label" for="flexCheckChecked">
                I have read, understood and agreed to the terms and conditions
              </label>
              <h6 class="checkBoxx">{{ checkBox }}</h6>
            </div>

            <div class="form-group" v-if="userType != ''">
              <button
                @click="joinOvadraft()"
                class="btn continue-btn"
                :disabled="!conditionterms || loading"
              >
                Accept terms and conditions
                <i
                  class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                  v-if="loading"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transaction-pin-modal></transaction-pin-modal>
  </div>
</template>

<script>
import { Service } from "../../store/service";
import moment from "moment";
import $ from "jquery";
const Api = new Service();
import TransactionPinModal from "./TransactionPinModal.vue";
// import paystack from "vue-paystack";
// import { staticdata } from "../../src/data";
export default {
  name: "JoinOvadraft",
  components: {
    TransactionPinModal
    // paystack
  },
  props: [],
  data() {
    return {
      loading: false,
      checkBox: "",
      conditionterms: "",
      userType: ""
    };
  },
  computed: {
    welcome() {
      return `${this.dataForRegistration}`;
    }
  },
  // beforeMount() {},
  created() {
    // this.$root.$refs.usermodal = this;
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("shelta-user"));
    if (this.user.overdraft_user_type != null) {
      this.$router
        .push({
          path: `/dashboard/ovadraft`
        })
        .catch(() => {});
    }
    // localStorage.setItem("shelta-user", JSON.stringify(res.data.user));
  },
  methods: {
    async getDetails() {
      this.pinloader = true;
      try {
        await Api.getRequest(`getcarddetailsbypin/${this.cardpin}`)
          .then(res => {
            this.carddetails = res.data.card;
            this.pinloader = false;
            if (res.data.success) {
              return (this.pindetailsshow = true);
            }
            if (res.data.error) {
              this.pindetailsshow = false;
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.pinloader = false;
            this.pindetailsshow = false;
            console.log(err);
          });
      } finally {
        // console.log("close");
      }
    },
    async joinOvadraft() {
      this.loading = true;
      try {
        await Api.postRequest(`joinoverdraft`, {
          overdraft_user_type: this.userType
        })
          .then(async res => {
            this.loading = false;
            if (res.data.success) {
              this.$store.commit("setApiSuccess", res.data.success);
              localStorage.setItem(
                "shelta-user",
                JSON.stringify(res.data.user)
              );
              if (this.userType === "user") {
                await this.$root.$refs.createpinmodal.openTransactionPin();
              }
              if (this.userType === "agent") {
                this.$router
                  .push({
                    path: `/dashboard/ovadraft`
                  })
                  .catch(() => {});
              }
            }
            if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            this.loading = false;
            this.$store.commit("setApiFailed", "Error occured");
          });
      } finally {
        // console.log("close");
      }
    }
  },
  filters: {
    moment: function(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    }
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

$primary: #0033ea;
$secondary: #ffffff;
$yellow: #ffb100;
$tatiary: #a2b0e3;
$black: #000000;
$sheltaBlack: #262626;
$offBlack: #262626;
$limeGreen: #3ec250;
$sheltaGrey: #555c74;

::-webkit-scrollbar {
  display: none;
}
.options-holder {
  // padding-top: 40px;
  // height: 91.4vh;
  height: calc(100vh - 3.46rem);
  overflow: auto;
  // margin: 0;
  // padding: 0;
  // background: $secondary;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
}

.top-text {
  margin-bottom: 40px;
  margin-top: 30px;
  text-align: center;
  h3 {
    color: #2b3352;
    // text-align: left;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 2px;
  }
  p {
    color: $sheltaGrey;
    // text-align: left;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}

.options-container {
  min-height: 100%;
  overflow: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  margin: 30px;
  background: $secondary;
  border-radius: 8px;
}

.top-card {
  border-radius: 16px;
  padding: 24px;
  min-height: 130px;
  min-width: 250px;
  cursor: pointer;
  h6,
  h4 {
    color: $sheltaGrey;
    text-align: left;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    text-transform: capitalize;
    margin-bottom: 8px;
  }
  h5 {
    color: $sheltaGrey;
    text-align: left;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    // text-transform: capitalize;
    margin-bottom: 8px;
    text-align: center;
    margin: 10% auto;
  }
  h4 {
    border: 1px solid $sheltaGrey;
    border-radius: 4px;
    font-size: 12px;
    padding: 4px 8px;
    font-weight: 600;
  }
  p {
    color: $sheltaGrey;
    text-align: left;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    text-transform: capitalize;
    margin-bottom: 0;
  }
}
.first-card {
  // background: #f2f2f2;
  background: #f5f7fe;
}
// .first-card:hover {
//   background: $primary;
//   h5 {
//     color: $secondary !important;
//   }
// }
.card-holder {
  margin-bottom: 40px;
}
.activeCard {
  background: $primary;
  h5 {
    color: $secondary !important;
  }
}
.customer-icon {
  width: 40px;
}
@media (max-width: 767px) {
  .mobile-card {
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-bottom: 30px;
  }
  .top-card {
    min-width: 100%;
    min-width: 0;
    width: 124px;
    h5 {
      text-transform: none;
    }
  }
}
.terms-conditions {
  margin-top: 50px;
  margin-bottom: 20px;
}
.tc {
  font-family: Lato;
  font-size: 18px;
  text-align: left;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #2b3352;
}
.terms-body {
  h5 {
    font-family: Lato;
    font-size: 20px;
    text-align: left;
    line-height: 30px;
    font-weight: 400;
  }
  p {
    font-family: Lato;
    font-size: 16px;
    text-align: justify;
    line-height: 30 px;
    font-weight: 400;
  }
}

.form_check {
  margin: 19px 20px 19px 0px;
  text-align: start;
  label {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    padding-left: 8px;
    // line-height: 150px;
    color: $sheltaGrey;
  }
  input {
    width: 18px;
    height: 18px;
  }
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #6c6c6c;
}
.checkBoxx {
  color: #dc3545;
  font-family: Lato;
  text-align: left;
  padding-top: 0.5rem;
  // margin-bottom: 0;
}

.continue-btn {
  border-radius: 6px;
  background: $primary;
  color: $secondary;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  // text-transform: capitalize;
  height: 48px;
  outline: 0;
  box-shadow: none;
  margin: 40px 0;
  float: right;
}

@media (max-width: 767px) {
  .form_check {
    margin: 19px 20px 19px 0px;
  }
  .continue-btn {
    width: 100%;
  }
}
</style>
